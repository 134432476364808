import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import cobarct_us from '../assets/images/team.png'
import { CustomButton } from './helpers'
import { useNavigate } from 'react-router-dom'
export const ContactUs = ({ title, subTitle }) => {
    const navigate = useNavigate()
    return (
        <Box py={7} bgcolor={"#ffffff"} >
            <Container maxWidth="lg" >
                <Box className="contact-us-card">
                    <Grid container spacing={3} alignItems={"center"} >
                        <Grid item xs={12} md={8}>
                            <h2 dangerouslySetInnerHTML={{ __html: title || "Grow your ideal team" }}></h2>
                            <p dangerouslySetInnerHTML={{ __html: subTitle || "Cut through the noise and find the tech professionals who truly fit your business. Teckable makes hiring straightforward and effective." }}></p>
                            <Stack direction={{ xs: 'column', sm: 'row', }}
                                my={2} spacing={2} alignItems={"center"}>
                                <CustomButton text={"FIND RESOURCES"} className={"secondary large"} onClick={() => navigate("/signup")} />
                                <CustomButton text={"TALK TO AN EXPERT"} className={"outline large"} onClick={() => navigate("/expert")} />
                                <Typography
                                    onClick={() => navigate('/pricing')}
                                    sx={{ cursor: "pointer" }}
                                >
                                    <span style={{ textDecoration: "underline", fontSize: "12px", color: "#fff", fontWeight: "600", }}>Teckable vs Alternatives</span>
                                    <span style={{ textDecoration: "none", fontSize: "12px", color: "#fff", fontWeight: "600", }}>&nbsp;(Pricing)</span>
                                </Typography>                            </Stack>
                            <Stack direction={"row"} spacing={2} sx={{ justifyContent: { md: "start", xs: "center" }, alignItems: { xs: "center", md: "start" }, flexWrap: "wrap" }}>
                                <p className='banner' >✓ 500+ core network proficiencies</p>
                                <p className='banner' >✓ 5 days to onboard</p>
                                <p className='banner' >✓ 3+ Years Resource retention</p>
                            </Stack>

                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: { xs: "none", md: "block" } }}>
                            <img src={cobarct_us} alt={"cobarct_us"} style={{ width: "100%" }} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}
