import React, { lazy, Suspense,useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import AboutUs from './pages/about-us';
import SignUp from './pages/sign-up';
import { Loader } from './components/loader';
import Skills from './pages/skill';
import Developers from './pages/developers';
import Expert from './pages/expert';
import Pricing from './pages/pricing';
import FAQs from './pages/faq';
import Testimonial from './pages/testimonials';
import PrivacyPolicy from './pages/cookie-&-privacy-policy/privacy';
import CookiePolicy from './pages/cookie-&-privacy-policy/cookie-&-policy';
import ScrollToTop from './components/scroll';
import GTMHandler from './utils/gtm';

const LandingPage = lazy(() => import('./pages/landing-page'));
const Why = lazy(() => import('./pages/why'));

function App() {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-37958VW06E';
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-37958VW06E');
    `;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);


  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <GTMHandler />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/why" element={<Why />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/skills-explore' element={<Skills />} />
            <Route path='/developers' element={<Developers />} />
            <Route path='/expert' element={<Expert />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/faq' element={<FAQs />} />
            <Route path='/case-studies' element={<Testimonial />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/cookie-policy' element={<CookiePolicy />} />

          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
