import React, { createContext, useState } from 'react'
import ResponsiveAppBar from '../../components/header'
import { Box, Container } from '@mui/material'
import './_pricing.scss'
import { PricingCard } from './pricing_card'
import { Footer } from '../../components/footer'
import { TraditionalHiring } from './traditional_hiring'
import { Search } from './search'
import { Helmet } from 'react-helmet-async';
import { Element, scroller } from 'react-scroll';



export const DataProvider = createContext(null)

const Pricing = () => {
    const [data, setData] = useState({ resource: "software-developers", expectations: 40, country: "other", currency: "united-states-dollar" })

    const amount = {
        'canadian-dollar': { value: 1.38, symbol: 'C$', symbolPosition: 'before' },
        'danish-krone': { value: 6.83, symbol: 'kr.', symbolPosition: 'after' },
        'euro': { value: 0.92, symbol: '€', symbolPosition: 'before' },
        'norwegian-krone': { value: 10.72, symbol: 'kr', symbolPosition: 'after' },
        'pound-sterling': { value: 0.77, symbol: '£', symbolPosition: 'before' },
        'swedish-krona': { value: 10.39, symbol: 'kr', symbolPosition: 'after' },
        'swiss-franc': { value: 0.86, symbol: 'CHF', symbolPosition: 'before' },
        'united-states-dollar': { value: 1, symbol: '$', symbolPosition: 'before' }
    };


    const resourcePricing = {
        'software-developers': { start: 15, end: 30 },
        'ui-ux-designers': { start: 15, end: 30 },
        'qa-engineers': { start: 15, end: 30 },
        'data-engineers': { start: 20, end: 35 },
        'data-scientists': { start: 30, end: 40 },
        'devops-engineers': { start: 20, end: 35 },
        'business-analysts': { start: 30, end: 40 },
        'project-manager': { start: 30, end: 40 }
    };

    const scrollToHiring = () => {
        scroller.scrollTo('traditionalHiringSection', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    };
    return (
        <>
            <Helmet>
                <title>Find Remote Talent At Flexible Pricing Plans</title>
                <meta name='description' content='Explore Teckable’s transparent pricing for customized IT solutions designed to fit your business needs, ensuring value and flexibility at every step.' />
                <link rel="canonical" href="https://www.teckable.com/pricing" />

            </Helmet>

            <DataProvider.Provider value={{ data, setData, resourcePricing, amount, scrollToHiring  }}>

                <Box sx={{ backgroundColor: "#5258fb" }}>
                    <Box>
                        <ResponsiveAppBar />
                    </Box>
                    <Container maxWidth="lg" sx={{ pt: 10, pb: 5 }}>
                        <PricingCard />
                    </Container>

                </Box>
                <Element name="traditionalHiringSection">
                    <TraditionalHiring />
                </Element>
            </DataProvider.Provider>

            <Search />
            <Footer />
        </>
    )
}

export default Pricing