import { Container, Grid } from '@mui/material'
import React from 'react'
import team from '../../assets/images/Team.jpeg'
// import img from '../../assets/images/bring.webp'
import img1 from '../../assets/company/how_it_works_img_1.webp'

const timelineEvents = [
  {
    date: "2012",
    title: "The birth of Teckable",
    description: "Teckable is established, bridging the gap between exceptional talent and high-growth companies.",
    imgSrc: img1,
    imgAlt: "timeline"
  },
];

export const AboutUsFirst = () => {
  return (
    <>
      <section className='about-us'>
        <Container maxWidth="lg" sx={{ pt: 10, pb: 5 }}>
          <h1 >Matching global talent with ambitious businesses. We are Teckable - since 2012</h1>
          <p>We find and foster exceptional talent, matching them with businesses on the rise.</p>
          <div className='image-wrapper'>
            <img src={team} alt='team' loading='lazy' />
          </div>
        </Container>
      </section>
      <section className='timeline'>
        <Container maxWidth={"lg"} sx={{ overflow: { xs: 'hidden', md: "visible" }, py: { xs: 2, md: 0 } }}>
          <Grid container sx={{ alignItems: "center" }} >
            <Grid item xs={12} md={6} >
              <div className='sticky-col'>
                <p className='sub-title'>Simple Mission!</p>
                <p className="desc" >
                  Teckable was founded with a clear vision: to seamlessly connect exceptional resources with the opportunities they deserve. Building tech teams is inherently challenging, demanding substantial time and resources. A single misstep in the hiring process can create significant setbacks for scaling companies. Our co-founder and CEO, Sarswathi Mopuru, witnessed these challenges firsthand while running her health tech startup, Optimists (<a href='https://www.optimists.in/'>www.optimists.in</a>), in India.
                </p>
                <p className="desc" >
                  Since 2012, we have refined our vetting process, meticulously selecting remote tech experts and aligning them with opportunities that match their skills perfectly. At Teckable, we believe that leading companies deserve access to top-tier talent, and the best resources deserve to work with industry leaders. It's that simple                </p>
                <h5>How can we offer affordable pricing without compromising resource quality?
                </h5>

                <p className="desc" >
                  Recently, there has been a significant trend of talented professionals migrating from metro cities back to their tier 1 and tier 2 hometowns. This move allows them to spend quality time with their families and enjoy a more balanced lifestyle.                </p>
                <p className="desc" >
                  This shift presents us with an opportunity. We can recruit these skilled professionals and offer them competitive consulting fees. Since the cost of living in their hometowns is significantly lower than in metro areas, they can save more money despite earning the same or even slightly lower salaries. This approach enables us to provide high-quality resources at affordable prices without compromising on the quality of talent.                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className='timeline-card'  >

              <ul>
                {timelineEvents.map((event, index) => (
                  <li className="card" key={index}>
                    <img loading="lazy" src={event.imgSrc} width="190" height="175" alt={event.imgAlt} />
                    <span>{event.date}</span>
                    <h5>{event.title}</h5>
                    <p>{event.description}</p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>

        </Container>
      </section>
    </>


  )
}


