import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import success from '../assets/images/success.gif'
import fail from '../assets/images/failed.gif'

import { CustomButton } from './helpers';
const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "90%", md: 400 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px !important', // Corrected property name
    p: 4,
};

export default function SpringModal({ isSuccess, handleClose, open,handleSuccess }) {

    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                hideBackdrop={true}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} >
                        {isSuccess ?
                            <div className='enquiry-modal'>
                                <img src={success} alt={'success'} />
                                <p>Thank you for your enquiry. We will be in touch soon.</p>
                                <CustomButton text={"OKAY"} className={'bg-default'} onClick={() => handleSuccess()} />
                            </div> :
                            <div className='enquiry-modal'>
                                <img src={fail} alt={'fail'} />
                                <p>Something went wrong, please try again later.</p>
                                <CustomButton text={"TRY AGAIN"} className={'err'} onClick={() => handleClose()} />
                            </div>}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
