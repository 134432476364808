import { Container, Divider, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const skillsData = [
    {
        title: "Software Product Engineering",
        roles: ["Backend", "Frontend", "Fullstack", "Mobile", "Game Development"]
    },
    {
        title: "Data & Artificial Intelligence",
        roles: ["Data Engineering", "Data Science", "Machine Learning", "Business Intelligence"]
    },
    {
        title: "Cloud & Infrastructure",
        roles: ["DevOps"]
    },
    {
        title: "Quality Assurance & Design",
        roles: ["QA", "Design"]
    }
];
const skillsArray = [
    {
        skills: ["Node.js", "Python", "Java", ".Net", "PHP",]
    },
    {
        skills: ["React", "Angular", "Vue.js", "Svelte", "Bootstrap"]
    },
    {
        skills: ["Django", "Ruby on Rails", "Spring Boot", "MERN", "MEAN",
        ]
    },
    {
        skills: ["React Native", "Swift", "Kotlin", "Flutter", "Swift",
        ]
    }
]






export const Search = () => {
    const navigate= useNavigate()
    return (
        <section className='search-developers'>
            <Container maxWidth={"lg"}>
                <h2>Search developers by...</h2>
                <div className='search-list'>
                    <h5>Role</h5>
                    <Grid container spacing={2}>
                        {skillsData.map((skill) => (
                            <Grid item xs={12} md={3} key={skill.title}>
                                <div className='title'>{skill.title}</div>
                                {skill.roles.map((role) => (
                                    <p key={role}>{role}</p>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                    <Divider sx={{ my: { xs: 1, md: 2 }, border: "1px solid #fff" }} />
                    <h5>Skills</h5>
                    <Grid container spacing={2}>
                        {skillsArray.map((skill, index) => (
                            <Grid item xs={12} md={3} key={index}>
                                {skill.skills.map((skill,id) => (
                                    <p key={id}>{skill}</p>
                                ))}
                            </Grid>
                        ))}
                    </Grid>

                    <h5 style={{ marginTop: "12px",cursor:'pointer' }} onClick={() =>navigate('/skills-explore')}>See all skills</h5>

                </div>
            </Container>
        </section>
    )
}
