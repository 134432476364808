import { Grid, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { CustomButton } from '../../components/helpers'
import Hero from '../../assets/images/man.jpg'
import img from '../../assets/images/img.svg'
import img1 from '../../assets/images/img1.svg'
import img2 from '../../assets/images/img2.svg'
import { useNavigate } from 'react-router-dom'
import { Selector } from './selector'
import { DataProvider } from './index'

const benefits = [
    {
        img: img,
        title: "Adjustable Hours",
        description: "Take charge of your time and reduce fixed expenses, beginning with 40 hours a month."
    },
    {
        img: img1,
        title: "Dynamic Commitment",
        description: "Swiftly resize your team, making necessary adjustments in a matter of weeks."
    },
    {
        img: img2,
        title: "Personalized Support",
        description: "Work with our supportive team for customized help under different departments."
    }
];

export const PricingCard = () => {
    const navigate = useNavigate()
    const { data,resourcePricing,amount,scrollToHiring } = useContext(DataProvider)




    return (
        <section className='pricing_card'>
            <h1 >Pricing Plans</h1>
            <p>Simple and Flexible Terms, Perfectly Matched to Your Business.</p>
            <Selector />
            <div className='box'>
                <h2 >{Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * data.expectations * 4).toLocaleString()} <span>to</span> {Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * data.expectations * 4).toLocaleString()} <span>{amount[`${data.currency}`]["symbol"]}/month</span></h2>
                <p style={{fontWeight:600}}>
                    {amount[`${data.currency}`]["symbolPosition"] === 'before'
                        ? amount[`${data.currency}`]["symbol"] === "£" ? `${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start)}-${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end)}` :
                            amount[`${data.currency}`]["symbol"] === "CHF" ? `${amount[`${data.currency}`]["symbol"]} ${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start)}-${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end)}` :
                                `${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start)}-${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end)}`
                        : `${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start)} - ${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end)} ${amount[`${data.currency}`]["symbol"]}`
                    }/hour
                </p>

                <Stack direction={{ xs: 'column', sm: 'row' }}
                    sx={{ justifyContent: "center", alignItems: "end", gap: 2 }}>
                    <CustomButton text={"FIND RESOURCES"} className={" large bg-default"} onClick={() => navigate("/signup")} />
                    <CustomButton text={"TALK TO AN EXPERT"} className={" large bg-default"} onClick={() => navigate("/expert")} />
                    <Typography
                        onClick={() => scrollToHiring()}
                        sx={{ cursor: "pointer" }}
                    >
                        <span style={{ textDecoration: "underline", fontSize: "16px", fontWeight: "600", }}>Teckable vs Alternatives</span>
                        <span style={{ textDecoration: "none", fontSize: "16px", fontWeight: "600", }}>&nbsp;(Pricing)</span>
                    </Typography>
                </Stack>
                <Stack direction={"row"} spacing={2} mt={3} sx={{ justifyContent: { md: "start", xs: "center" }, alignItems: { xs: "center", md: "start" }, flexWrap: "wrap" }}>
                    <p className='banner'>✓ 500+ core network proficiencies</p>
                    <p className='banner'>✓ 5 days to onboard</p>
                    <p className='banner'>✓ 3+ Years Resource retention</p>
                </Stack>
                <p className='pricing-advantages'>
                    No hidden fees • Includes HR and admin support
                </p>
                <img src={Hero} alt="Hero" className='hero ' />
            </div>


            <Grid container className='benefits' spacing={2}>
                {benefits.map((item) => (
                    <Grid item xs={12} key={item.title} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <img src={item.img} alt="Icon" />
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </Grid>
                ))}

            </Grid>

        </section>
    )
}
