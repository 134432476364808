import React, { useState } from 'react';
import { OutlinedInput, InputAdornment, List, ListItem, Paper, Container, Card, Stack, Typography, Divider, Chip, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
const Roles = [
    "Backend",
    "Frontend",
    "Fullstack",
    "Mobile",
    "Game Development",
    "Data Engineering",
    "Data Science",
    "Machine Learning",
    "Business Intelligence",
    "DevOps",
    "QA",
    "Design"
];

const alphabets = ["#", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

export const Search = ({ roles, setRoles, skills, letter, setLetter, allSkills }) => {
    const [showAlphabet, setShowAlphabet] = useState(false);
    const [showRoles, setShowRoles] = useState(false);
    const matches = useMediaQuery('(min-width:600px)', { noSsr: true });

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredSkills, setFilteredSkills] = useState({});

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = {};
        for (const [category, skillArray] of Object.entries(allSkills)) {
            console.log(category, skillArray);
            
            filtered[category] = skillArray.filter(skill => skill.toLowerCase().includes(value));
        }
        setFilteredSkills(filtered);
    };
    
    const handleDelete = (skillToDelete) => {
        if (roles.includes(skillToDelete.split(" ").join(""))) {
            setRoles((prevRoles) => prevRoles.filter((role) => role !== skillToDelete.split(" ").join("")));
        } else {
            setRoles((prevRoles) => [...prevRoles, skillToDelete.split(" ").join("")]);
        }
    };
    const handleClick = (skill) => {
        if (skill === ".NET developers") {
          window.location.href = "https://teckable.com/skills/hire-senior-and-proven-net-developers/";
        }
      };
    return (
        <section className='search'>
            <Container maxWidth="lg">
                <Card sx={{ p: { xs: 2, md: 4 }, borderRadius: 3 }}>
                    <Stack direction={{ xs: "column", md: "row" }} sx={{ alignItems: "baseline" }} spacing={2}>
                        <p className='search-label'>Search skills</p>
                        <Stack direction={"column"} sx={{ width: "100% " }}>
                            <OutlinedInput
                                sx={{ borderRadius: "5px" }}
                                size='small'
                                placeholder='Search Skills'
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            {(searchTerm && Object.keys(filteredSkills).length > 0) && (
                                <Paper sx={{ mt: 1, boxShadow: 0, maxHeight: "200px", overflow: "auto", border: "1px solid #cecece", borderRadius: "6px" }}>
                                    <List>
                                        {Object.entries(filteredSkills).map(([category, skillArray]) => (
                                            skillArray.map((skill, index) => (
                                                <ListItem
                                                    sx={{ cursor: "pointer", "&:hover": { background: "aliceblue" } }}
                                                    key={`${category}-${index}`}
                                                >
                                                    <div>
                                                        <strong onClick={()=>handleClick(skill)}>{skill}</strong>
                                                    </div>
                                                </ListItem>
                                            ))
                                        ))}
                                    </List>
                                </Paper>
                            )}
                        </Stack>

                        <Stack direction={"row"}>
                            <Typography
                                className='a-z'
                                sx={{ display: { xs: "flex", md: "none !important" } }}
                                onClick={() => {
                                    setShowRoles(!showRoles);
                                    setShowAlphabet(false);
                                }}
                            >
                                ROLES {showRoles ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
                            </Typography>

                            <p className='a-z'
                                onClick={() => {
                                    setShowRoles(false);
                                    setShowAlphabet(!showAlphabet);
                                }}
                            >
                                A-Z {showAlphabet ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
                            </p>
                        </Stack>
                    </Stack>

                    <Stack>
                        {showAlphabet ? (
                            <>
                                <Stack mt={{ xs: 2, md: 4 }} direction={"row"} gap={{ xs: 2, md: 0 }} sx={{ display: "flex", flexWrap: "wrap", justifyContent: { md: "space-around", xs: '-moz-initial' }, }}>
                                    {alphabets.map((alphabet) => <p className='alphabet' onClick={() => setLetter(alphabet)} style={{ color: alphabet === letter && "#5258fb" }} key={alphabet}>{alphabet}</p>)}
                                </Stack>
                                <Divider sx={{ mt: 1 }} />
                            </>
                        ) : null}
                    </Stack>
                    {(matches || showRoles) && (
                        <Stack mt={{ xs: 2, md: 4 }} direction={"row"} spacing={2} sx={{ flexWrap: "wrap", justifyContent: "center", rowGap: 2 }}>
                            {Roles.map((skill, index) => (
                                <Chip
                                    sx={{ py: 2, px: 1, borderRadius: "26px", fontWeight: 500, color: "#475467" }}
                                    key={index}
                                    variant={roles.includes(skill.split(" ").join("")) ? "filled" : "outlined"}
                                    label={skill}
                                    clickable
                                    onClick={roles.includes(skill.split(" ").join("")) ? undefined : () => handleDelete(skill)}
                                    onDelete={roles.includes(skill.split(" ").join("")) ? () => handleDelete(skill) : undefined}
                                    deleteIcon={roles.includes(skill.split(" ").join("")) ? <CancelIcon /> : null}
                                />


                            ))}

                        </Stack>
                    )}
                </Card>
            </Container>
        </section>
    );
};
