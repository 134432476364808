import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <div className="app-wrapper"> */}

      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    {/* </div> */}
  </React.StrictMode>,
);

reportWebVitals();
