import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { CustomButton } from './helpers';
import { Stack } from '@mui/material';
import '../index.scss';
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/logo/logo.png'

const pages = [{ name: 'Why Teckable', url: "/why" }, { name: 'Pricing', url: "/pricing" }, { name: 'About Us', url: "/about-us" }, {name: 'Case Studies', url :"/case-studies"}];
const options = [{ name: 'Skills Explore', url: '/skills-explore' }, { name: 'Learning Hub', url: "https://teckable.com/skills/articles/" }, { name: 'FAQs', url: "/faq" }]
const language = [{ code: "GB", text: "English" }, { code: "DE", text: "Deutsch" }]
const allPages = [{ name: 'Why Teckable', url: "/why" }, { name: 'Pricing', url: "/pricing" }, { name: 'About Us', url: "/about-us" }, { name: 'Skills Explore', url: '/skills-explore' }, { name: 'Learning Hub', url: "/" }, { name: 'FAQs', url: "/faq" }]
function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorLan, setAnchorLan] = React.useState(null);
    const navigate = useNavigate()
    const open = Boolean(anchorEl);
    const openLan = Boolean(anchorLan);
    const handleClickLan = (event) => {
        setAnchorLan(event.currentTarget);
    };
    const handleCloseLan = () => {
        setAnchorLan(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };



    return (
        <header style={{ backgroundColor: "#5258fb", color: "#fff", boxShadow: 2, position: "fixed", zIndex: 100, width: "100%" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, maxHeight: "100%", alignItems: "center", mr: 2 }}>

                        <img src={Logo} alt='Teckable' style={{ height: "30px", cursor: "pointer" }} onClick={() => navigate("/")} />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                        {pages.map((page) => (
                            <Typography
                                key={page.name}
                                className='title text cur'
                                onClick={() => { navigate(page.url) }}
                            >
                                {page.name}
                            </Typography>
                        ))}
                        <Stack direction={"row"} sx={{ alignItems: "center", cursor: "pointer" }} aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <p className='title'>Resources</p>

                            <KeyboardArrowDownIcon fontSize='small' />
                        </Stack>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {options.map((option) => (
                                option.name === 'Learning Hub' ? (
                                    <MenuItem className='title' key={option.name}>
                                        <a className='title' style={{ color: "#000", textDecoration: "none" }} href={option.url} rel="noopener noreferrer">
                                            {option.name}
                                        </a>
                                    </MenuItem>
                                ) : (
                                    <MenuItem className='title' key={option.name} onClick={() => navigate(option.url)}>
                                        {option.name}
                                    </MenuItem>
                                )
                            ))}


                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 0, display: { md: "block", xs: "none" } }} >
                        <Stack gap={1} direction={"row"} sx={{ alignItems: "center" }} >
                            <CustomButton text={"FIND RESOURCES"} className={"secondary header"} onClick={() => navigate("/signup")} />
                            <Stack direction={"row"} sx={{ alignItems: "center", cursor: "pointer" }} aria-label="more"
                                id="long-button"
                                aria-controls={openLan ? 'long-menu' : undefined}
                                aria-expanded={openLan ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClickLan}>
                                <LanguageIcon fontSize='medium' sx={{ cursor: "pointer" }} />
                                <KeyboardArrowDownIcon fontSize='small' />
                            </Stack>

                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorLan}
                                open={openLan}
                                onClose={handleCloseLan}
                            >
                                {language.map((option) => (
                                    <MenuItem key={option} onClick={handleCloseLan}>
                                        <Stack gap={1} direction={"row"} sx={{ alignItems: "center" }} >
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option?.code.toLowerCase()}.png`}
                                                alt=""
                                            /><Typography className='title' sx={{ color: "#000" }}>{option.text}</Typography>
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Stack>
                    </Box>

                    {/* Mobile view */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, maxHeight: "100%", alignItems: "center", mr: 2 }}>
                        <img src={Logo} alt='Teckable' style={{ height: "30px", cursor: "pointer" }} onClick={() => navigate('/')} />
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <Stack direction={"row"} sx={{ alignItems: "center", cursor: "pointer" }} aria-label="more"
                            id="long-button"
                            aria-controls={openLan ? 'long-menu' : undefined}
                            aria-expanded={openLan ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClickLan}>
                            <LanguageIcon fontSize='medium' sx={{ cursor: "pointer" }} />
                            <KeyboardArrowDownIcon fontSize='small' />
                        </Stack>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        padding: "12px",
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' }, p: 5
                            }}
                        >
                            {allPages.map((page) => (
                                <MenuItem key={page.name} onClick={() => { navigate(page.url) }}>
                                    <Typography className='title-text' textAlign="center" sx={{ fontWeight: 600 }}>{page.name}</Typography>
                                </MenuItem>
                            ))}

                            <CustomButton text={"FIND RESOURCES"} className={"bg-default header"} onClick={() => navigate("/signup")} />
                        </Menu>

                    </Box>

                </Toolbar>
            </Container>

        </header>
    );
}
export default ResponsiveAppBar;
