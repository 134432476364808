import { Container, Grid } from "@mui/material";
import React from "react";
import speedImg from "../../assets/images/speed.svg";
import impactImg from "../../assets/images/impact.svg";
import qualityImg from "../../assets/images/quality.svg";
import personalImg from "../../assets/images/personal.svg";

const features = [
    {
        imgSrc: speedImg,
        imgAlt: "feature",
        heading: "Excellence",
        description: "We strive for the highest standards in all our work, ensuring exceptional quality and continuous improvement."
    },
    {
        imgSrc: personalImg,
        imgAlt: "feature",
        heading: "Integrity",
        description: "We conduct our business with honesty and transparency, building trust and long-term relationships with our clients."
    },
    {
        imgSrc: qualityImg,
        imgAlt: "feature",
        heading: "Customer Focus",
        description: "We prioritise our clients' needs, delivering tailored solutions and exceptional service to drive their success."
    },
    {
        imgSrc:impactImg,
        imgAlt: "feature",
        heading: "Innovation",
        description: "We embrace new technologies and innovative approaches, staying ahead of industry trends to provide cutting-edge solutions."
    }
];
// const data = [{ number: "94%", name: "Success rate" }, { number: "1000+", name: "Happy clients" }, { number: "3000+", name: "Talents in our network" }, { number: "35", name: "Countries" }, { number: "350k", name: "Hours worked via Teckable" }, { number: "2000+", name: "Successful matches" }]


export const OurValues = () => {
    return (
        <>
            <section className="values">
                <Container maxWidth={"lg"}>
                    <h2>What Defines Teckable</h2>
                    <p>Our actions are a testament to the values we uphold.</p>
                    <Grid container className="features" spacing={2}>
                        {features.map((feature) => (
                            <Grid key={feature.heading} item xs={12} md={2.8} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <img src={feature.imgSrc} alt={feature.imgAlt} />
                                <h3>{feature.heading}</h3>
                                <p>{feature.description}</p>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </section>
            {/* <section className="clients">
                <h2>Teckable in numbers</h2>
                <Container maxWidth="lg">
                    <FeatureGrid data={data} spacing={4} mt={3}/>
                </Container>
            </section> */}
        </>
    )
}