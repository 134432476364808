import React from 'react'
import ResponsiveAppBar from '../../components/header'
import { ContactUs } from '../../components/contact-us'
import { Footer } from '../../components/footer'
import './_testimonials.scss'
import { Container, Grid } from '@mui/material'
import lottie from '../../assets/clients/lottie.png'
import cy from '../../assets/clients/cy.png'
import fitify from '../../assets/clients/fitify.png'
import verb from '../../assets/clients/verb.png'
import formulaw from '../../assets/clients/form.png'
import optimists from '../../assets/clients/optimists.svg'
import { Helmet } from 'react-helmet-async';

const caseStudies = [
  {
    company: "Optimists Healthcare",
    url: "https://optimists.in/",
    img: optimists,
    description: "<a href='https://optimists.in/' target='_blank'>Optimists</a> is a healthcare technology platform offering doctor consultations and personalised dashboards to patients.",
    challenges: "The healthcare industry faces unique challenges in mobile app development, including ensuring data security, integrating with existing healthcare systems, and providing a seamless user experience. Optimists Healthcare needed a mobile app that was not only secure and compliant with healthcare regulations but also intuitive and accessible to patients of all ages and technological proficiencies. The app needed to offer a range of functionalities, from appointment scheduling and tele-health consultations to access to medical records and personalised health tips.",
    outcome: "The mobile app development project for Optimists Healthcare has set a new benchmark for digital healthcare services. By leveraging mobile technology, Optimists Healthcare has successfully improved patient engagement, operational efficiency, and overall satisfaction. This case study demonstrates the transformative impact of digital solutions in healthcare, paving the way for future innovations that continue to put patient care at the forefront."
  },
  {
    company: "Lottie",
    url: "https://lottie.org/",
    img: lottie,
    description: "<a href='https://lottie.org/' target='_blank'>Lottie</a> connects elderly people to the UK's best care homes.",
    challenges: "The primary challenges facing Lottie included maintaining a high standard of software quality amidst frequent updates, ensuring user data security, and optimising the platform for ease of use by elderly individuals. With the platform's wide range of functionalities, manual testing was time-consuming and prone to human error, leading to delays in releases and potential vulnerabilities in user experience and data security.",
    outcome: "Lottie’s investment in test automation has dramatically improved its platform's efficiency, reliability, and security, setting a new standard for digital services in the elderly care sector. By prioritising automated testing, Lottie not only enhanced its operational performance but also reinforced its commitment to providing a safe, user-friendly platform for the elderly and their families. This case study showcases the pivotal role of test automation in accelerating digital transformation in the healthcare industry, ensuring that technology serves as a bridge to better care and support."
  },
  {
    company: "VERB",
    url: "https://verbbrands.com/",
    img: verb,
    description: "<a href='https://verbbrands.com/' target='_blank'>VERB</a> is the world's only luxury data-driven, creative media agency.",
    challenges: "The primary challenge was to create a digital experience that embodied luxury and elegance while remaining highly functional and user-friendly. Verb’s clientele expected not just visual appeal but also ease of navigation, personalised content, and seamless interaction across all digital touch points. The agency’s website and mobile app needed to communicate its brand values effectively while providing intuitive user pathways to explore services, portfolios, and contact options.",
    outcome: "The UI/UX redesign project for Verb Luxury Marketing Agency exemplified how a meticulously crafted digital experience could elevate a brand’s online presence, resonating with its target audience’s expectations of luxury and sophistication. This case study underscores the importance of aligning design with brand identity and user needs, showcasing the transformative power of effective UI/UX design in the luxury marketing sector."
  },
  {
    company: "Formulaw",
    url: "https://formu.law/",
    img: formulaw,
    description: "<a href='https://formu.law/' target='_blank'>Formulaw</a> is a platform built to connect people to lawyers.",
    challenges: "The primary challenges in developing the Formulaw platform included creating a secure and reliable environment for users to share sensitive information, implementing an intuitive matching algorithm to connect users with the most suitable lawyers for their specific legal needs, and ensuring a seamless and efficient user experience. The platform needed to cater to a broad audience, accommodating various legal fields and jurisdictions.",
    outcome: "The Formulaw platform has successfully revolutionised how individuals and businesses connect with legal professionals, embodying the potential of technology to demystify and facilitate access to essential services. Through strategic software development focused on security, intelligent matchmaking, and an exceptional user experience, Formulaw has set a new industry standard for legal service platforms. This case study exemplifies the transformative impact of digital solutions on traditional industries, offering a blueprint for future innovations in legal tech."
  },
  {
    company: "FITIFY",
    url: "https://fitify.ae/",
    img: fitify,
    description: "<a href='https://fitify.ae/' target='_blank'>FITIFY</a> is an interior design and fit-out agency in the United Arab Emirates.",
    challenges: "The challenge lay in creating a digital experience that not only reflected FITIFY's brand identity and design ethos but also provided a seamless and intuitive user journey. The existing platforms needed an overhaul to improve navigation, aesthetic appeal, and interaction, ensuring that prospective clients could easily access project portfolios, book consultations, and understand FITIFY’s services. Moreover, the design needed to appeal to a luxury market, necessitating a balance between elegance and usability.",
    outcome: "The UI/UX redesign for FITIFY not only elevated their online presence but also effectively translated their brand's luxury and sophistication into a digital format. This case study underscores the importance of aligning digital experiences with brand identity, particularly in industries where visual appeal plays a crucial role in client decision-making. By focusing on visual storytelling, usability, and mobile optimization, FITIFY successfully created a digital platform that serves as an extension of their physical design excellence, setting a new benchmark for luxury interior design agencies."
  },
  {
    company: "Codeyoung",
    url: "https://www.codeyoung.com/",
    img: cy,
    description: "<a href='https://www.codeyoung.com/' target='_blank'>Codeyoung</a> is a platform where your kids can let their imaginations run wild. Students are taught to be creative, innovative and use coding in the most unusual way possible.",
    challenges: "CodeYoung's primary challenges included handling increasing traffic, maintaining consistent application performance across geographies, and ensuring data security and compliance with international standards. The platform needed a robust cloud infrastructure that could scale dynamically with demand, facilitate global content delivery, and protect sensitive student information.",
    outcome: "CodeYoung's transition to a cloud-based infrastructure with tailored solutions for scalability, performance, and security has set a new standard for EdTech platforms. By embracing cloud deployments, CodeYoung has not only overcome its operational challenges but also positioned itself for sustainable growth and innovation in the digital education space. This case study exemplifies the transformative potential of cloud technologies in addressing the unique needs of EdTech platforms, ensuring they can deliver quality education to students anywhere in the world."
  }
];


const Testimonial = () => {
  return (
    <>
      <Helmet>
        <title>Case Studies - Success Stories From Teckable</title>
        <meta name='description' content='Explore how Teckable’s IT solutions drive innovation, improve performance, and enhance security in our client case studies.' />
        <link rel="canonical" href="https://www.teckable.com/case-studies" />
      </Helmet>
      <ResponsiveAppBar />
      <section className='testimonials'>
        <Container maxWidth={"lg"} >
          <h1>Case Studies</h1>
          <p>Navigating the complexity of customer and business demands is not a small task. For organisations to stay relevant, they must adapt to the ever-evolving needs of customers while accelerating real growth through change. We establish unique, action-oriented strategies to build iconic brands.</p>
        </Container>
      </section>
      <section className='companies'>
        <Container maxWidth={"lg"} sx={{ py: { md: 7, xs: 3 } }} >
          <Grid container spacing={3}>
            {caseStudies.map((caseStudie) => (
              <Grid item key={caseStudie.company} xs={12} md={6}>
                <img src={caseStudie.img} alt={caseStudie.company} />
                <p dangerouslySetInnerHTML={{ __html: caseStudie.description }} />
                <h6>Challenges:</h6>
                <p>{caseStudie.challenges}</p>
                <h6>Outcome:</h6>
                <p>{caseStudie.outcome}</p>
              </Grid>


            ))}

          </Grid>
        </Container>
      </section>
      <ContactUs />
      <Footer />
    </>

  )
}

export default Testimonial