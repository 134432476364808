import { Container } from '@mui/material'
import React, { useContext } from 'react'
import { CustomButton } from '../../components/helpers'
import { useNavigate } from 'react-router-dom'
import { DataProvider } from './index'

export const TraditionalHiring = () => {
    const navigate = useNavigate()
    const { data, resourcePricing, amount } = useContext(DataProvider)

    return (
        <section className='traditional_hiring'>
            <Container maxWidth={"lg"}>
                <h2>Teckable vs  traditional Hiring</h2>
                <p> Achieve more with remote talent while maintaining financial control.</p>
                <div className="table-wrapper">
                    <div className="table">
                        <div className="row row-header">
                            <div className="column"></div>
                            <div className="column highlighted"><span>Teckable</span></div>
                            <div className="column"><span>Traditional agency</span></div>
                            <div className="column"><span>Traditional employment</span></div>
                        </div>
                        <div className="row">
                            <div className="column"><span>Cost per hour</span></div>
                            <div className="column highlighted"><span>  {amount[`${data.currency}`]["symbolPosition"] === 'before'
                                ? amount[`${data.currency}`]["symbol"] === "£" ? `${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start)}-${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end)}` :
                                    amount[`${data.currency}`]["symbol"] === "CHF" ? `${amount[`${data.currency}`]["symbol"]} ${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start)}-${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end)}` :
                                        `${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start)}-${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end)}`
                                : `${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start)} - ${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end)} ${amount[`${data.currency}`]["symbol"]}`
                            }</span></div>
                            <div className="column"><span> {amount[`${data.currency}`]["symbolPosition"] === 'before'
                                ? amount[`${data.currency}`]["symbol"] === "£" ? `${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * 3)}-${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * 3)}` :
                                    amount[`${data.currency}`]["symbol"] === "CHF" ? `${amount[`${data.currency}`]["symbol"]} ${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * 3)}-${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * 3)}` :
                                        `${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * 3)}-${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * 3)}`
                                : `${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * 3)} - ${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * 3)} ${amount[`${data.currency}`]["symbol"]}`
                            }</span></div>
                            <div className="column"><span>Higher</span></div>
                        </div>
                        <div className="row">
                            <div className="column highlighted"><span>Recruitment cost</span></div>
                            <div className="column "><span> {amount[`${data.currency}`]["symbolPosition"] === 'before'
                                ? amount[`${data.currency}`]["symbol"] === "£" ? `${amount[`${data.currency}`]["symbol"]}0` :
                                    amount[`${data.currency}`]["symbol"] === "CHF" ? `${amount[`${data.currency}`]["symbol"]} 0` :
                                        `${amount[`${data.currency}`]["symbol"]}0`
                                : `0 ${amount[`${data.currency}`]["symbol"]}`
                            }</span></div>
                            <div className="column "><span> {amount[`${data.currency}`]["symbolPosition"] === 'before'
                                ? amount[`${data.currency}`]["symbol"] === "£" ? `${amount[`${data.currency}`]["symbol"]}0` :
                                    amount[`${data.currency}`]["symbol"] === "CHF" ? `${amount[`${data.currency}`]["symbol"]} 0` :
                                        `${amount[`${data.currency}`]["symbol"]}0`
                                : `0 ${amount[`${data.currency}`]["symbol"]}`
                            }</span></div>
                            <div className="column"><span>                                {amount[`${data.currency}`]["symbolPosition"] === 'before'
                                ? amount[`${data.currency}`]["symbol"] === "£" ? `${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * 15).toLocaleString()}-${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * 15).toLocaleString()}` :
                                    amount[`${data.currency}`]["symbol"] === "CHF" ? `${amount[`${data.currency}`]["symbol"]} ${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * 15).toLocaleString()}-${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * 15).toLocaleString()}` :
                                        `${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * 15).toLocaleString()}-${amount[`${data.currency}`]["symbol"]}${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * 15).toLocaleString()}`
                                : `${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].start * 15).toLocaleString()} - ${Math.round(amount[`${data.currency}`]["value"] * resourcePricing[`${data.resource}`].end * 15).toLocaleString()} ${amount[`${data.currency}`]["symbol"]}`}
                            </span></div>
                        </div>
                        <div className="row">
                            <div className="column"><span>Time to hire</span></div>
                            <div className="column highlighted"><span>5 Days</span></div>
                            <div className="column"><span>1 — 2 months</span></div>
                            <div className="column"><span>1 — 4 months</span></div>
                        </div>
                        <div className="row">
                            <div className="column"><span>Quality-assured</span></div>
                            <div className="column highlighted"><span>Always</span></div>
                            <div className="column"><span>Sometimes</span></div>
                            <div className="column"><span>-</span></div>
                        </div>
                        <div className="row">
                            <div className="column"><span>Termination costs</span></div>
                            <div className="column highlighted"><span>-</span></div>
                            <div className="column"><span>Typically high</span></div>
                            <div className="column"><span>Typically high</span></div>
                        </div>
                        <div className="row cta-wrapper">
                            <div className="column"></div>
                            <div className="column">
                                <CustomButton text={"FIND RESOURCES"} className={"bg-default large"} onClick={() => navigate("/signup")} />
                            </div>
                            <div className="column"></div>
                            <div className="column"></div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}


