import React, { useState } from 'react'
import './_expert.scss'
import { Box, FormControl, FormControlLabel, Avatar, Grid, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography, Container } from '@mui/material';
import expert from '../../assets/developers/dev4.jpg'
import MuiPhoneNumber from 'mui-phone-number';
import { CustomButton } from '../../components/helpers';
import { object, string } from 'yup';
import { red } from '@mui/material/colors';
import ResponsiveAppBar from '../../components/header';
import { Footer } from '../../components/footer';
import { useNavigate } from 'react-router-dom';
import SpringModal from '../../components/modal';
import { Helmet } from 'react-helmet-async';;

const userSchema = object({
    phoneNumber: string().optional(),
    email: string().email('Invalid email address').required('Email is required'),
    name: string().required('Name is required'),
    engagementLength: string().required('Engagement length is required'),
    employeeCount: string().required('Employee count is required'),
});

const empCounts = [
    '2-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-2000 employees',
    '2001-5000 employees',
    '5001-10000 employees',
    '10001+ employees'
];
const engagementArray = ['More than 6 months', '3 to 6 months', '1 to 3 months', '1 to 4 weeks', 'I’m not really sure'];

const Expert = () => {
    const [data, setData] = useState({});
    const [errMsg, setErrMsg] = useState({})
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isSuccess, setIsSuccess] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleSuccess = () => {
        setOpen(false)
        navigate('/')
    }
    const handleSubmit = async () => {
        try {
            await userSchema.validate(data, { abortEarly: false });
            setErrMsg({});
            const response = await fetch('https://optimists-services-india-test.azurewebsites.net/api/notifications/submit-enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setIsSuccess(true)
                setOpen(true)
            } else {
                setIsSuccess(false)
                setOpen(true)
            }
        } catch (error) {
            if (error.inner) {
                const validationErrors = error.inner.reduce((acc, err) => {
                    acc[err.path] = err.message;
                    return acc;
                }, {});
                setErrMsg(validationErrors);
            } else {
                console.error('Failed to send email:', error);
            }
        }
    };
    return (
        <>
            <Helmet>
                <title>Talk to an Expert | Teckable</title>
            </Helmet>
            <ResponsiveAppBar />
            <div className='expert'>
                {/* <Container maxWidth="xl" sx={{ py: 2, mb: { xs: 5, md: 15 } }}>
                <img src={Logo} alt='Teckable' style={{ height: "30px" }} />
            </Container> */}
                <Container maxWidth={"lg"} className='expert-content'>
                    <h2 className='expert-title'>Talk to an expert</h2>
                    <Stack direction={"row"} gap={3} sx={{ alignItems: "center" }}>
                        <Avatar src={expert} sx={{ width: { xs: 45, md: 90 }, height: { xs: 45, md: 90 } }} alt='expert' />
                        <Stack direction={"column"} gap={1}>
                            <p>👋🏻 I'm John.</p>
                            <p>My expertise is in matching you with the right developers for your technical needs. I'm looking forward to answering any questions you may have.</p>
                        </Stack>
                    </Stack>
                    <FormControl fullWidth className='form-component' sx={{ mt: 3 }}>
                        <h2>What's your company size?</h2>
                        <Select
                            sx={{ border: '2px solid #fff', color: '#fff' }}
                            labelId="company-size-select-label"
                            id="company-size-select"
                            onChange={handleInputChange}
                            name="employeeCount"
                            value={data.employeeCount || ''}
                        >
                            {empCounts.map((empCount) => (
                                <MenuItem key={empCount} value={empCount}>
                                    {empCount}
                                </MenuItem>
                            ))}
                        </Select>
                        {errMsg.employeeCount && <Typography color={`${red[500]} !important`}>{errMsg.employeeCount}</Typography>}

                        <h2>How long will the engagement last?</h2>
                        <RadioGroup
                            aria-labelledby="engagement-length-radio-group-label"
                            name="engagementLength"
                            value={data.engagementLength || ''}
                            onChange={handleInputChange}
                        >
                            <Grid container spacing={2}>
                                {engagementArray.map((item) => (
                                    <Grid item xs={12} md={6} key={item}>
                                        <Box className="last">
                                            <FormControlLabel value={item} control={<Radio />} label={item} />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </RadioGroup>
                        {errMsg.engagementLength && <Typography color={`${red[500]} !important`}>{errMsg.engagementLength}</Typography>}

                        <h2>We’d like to get in touch and hear about your needs</h2>
                        <Typography sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}>Name</Typography>
                        <TextField
                            id="name-input"
                            sx={{ color: "#fff", border: "2px solid #fff" }}
                            size="medium"
                            variant="outlined"
                            name="name"
                            value={data.name || ''}
                            onChange={handleInputChange}


                        />
                        {errMsg.name && <Typography color={`${red[500]} !important`}>{errMsg.name}</Typography>}

                        <Typography sx={{ mt: 2, color: '#344054', fontSize: '14px', fontWeight: 500 }}>Your company email</Typography>
                        <TextField
                            id="email-input"
                            sx={{ color: "#fff", border: "2px solid #fff" }}
                            size="medium"
                            variant="outlined"
                            name="email"
                            value={data.email || ''}
                            onChange={handleInputChange}
                        />
                        {errMsg.email && <Typography color={`${red[500]} !important`}>{errMsg.email}</Typography>}

                        <Typography sx={{ mt: 2, color: '#344054', fontSize: '14px', fontWeight: 500 }}>Phone number (Optional)</Typography>
                        <MuiPhoneNumber
                            defaultCountry="gb"
                            variant="outlined"
                            sx={{

                                border: "2px solid #fff",
                                '& .MuiInputBase-input': { color: '#fff' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#fff',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#fff',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#fff',
                                    },
                                },
                            }}
                            name="phoneNumber"
                            value={data.phoneNumber || ''}
                            onChange={(value) => setData((prevData) => ({ ...prevData, phoneNumber: value }))}
                            InputLabelProps={{
                                style: {
                                    color: "#fff",
                                },
                            }}
                        />
                        <Stack direction="row" alignItems="start" spacing={2} mt={2}>
                            <p >
                                We promise to never spam you. Read our{" "}
                                <span className='policy' onClick={() => navigate('/privacy-policy')}>
                                    privacy policy
                                </span>
                                .
                            </p>

                        </Stack>
                        <Stack direction={"row"} sx={{ mt: 2, justifyContent: "end" }}>
                            <CustomButton text={"BOOK A CALL"} className={"outline large"} onClick={() => handleSubmit()} />

                        </Stack>
                    </FormControl>

                </Container>

            </div>
            <Footer />
            <SpringModal handleClose={handleClose} handleSuccess={handleSuccess} isSuccess={isSuccess} open={open} handleOpen={handleOpen} />

        </>

    )
}

export default Expert