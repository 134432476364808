import React, { useEffect, useState } from 'react'
import ResponsiveAppBar from '../../components/header'
import { Box, Container } from '@mui/material'
import './_index.scss'
import { Search } from './search';
import { Footer } from '../../components/footer';
import { SkillsList } from './list';
import { ContactUs } from '../../components/contact-us';
import { Helmet } from 'react-helmet-async';;

const allSkills = {
    Backend: [
        ".NET developers", ".NET Core developers", ".NET framework developers", ".NET MAUI developers",
        ".NET Sitecore developers", "AB Initio developers", "Ada developers", "Agda developers",
        "Ajax developers", "Akka developers", "Apache Airflow developers", "Apache Camel developers",
        "Apache Flink developers", "Apache Groovy developers", "Apache Hive developers",
        "Apache HTTP Server developers", "Apache Iceberg developers", "Apache Kafka developers",
        "Apache Karaf developers", "Apache NiFi developers", "Apache Spark developers",
        "Apache ZooKeeper developers", "App developers", "AppAuth developers", "Appium developers",
        "ASP.NET developers", "AWS developers", "AWS Amplify developers", "AWS Athena developers",
        "AWS Beanstalk developers", "AWS CDK developers", "AWS EC2 developers", "AWS ECR developers",
        "AWS Kinesis developers", "AWS Lambda developers", "AWS S3 developers", "AWS Snowball developers",
        "AWS SQS developers", "AWS VPC developers", "Azure developers", "Azure Blob storage developers",
        "Azure Cloud developers", "Azure Data Factory developers", "Azure ML developers",
        "Azure Queue developers", "Azure Synapse developers", "Azure Web App developers",
        "Backbone developers", "Ballerina developers", "Bash developers", "BeautifulSoup developers",
        "BigQuery developers", "Blazor developers", "Business Objects developers", "C developers",
        "C# developers", "C++ developers", "Caffe developers", "CakePHP developers", "Calabash developers",
        "Capistrano developers", "Cassandra developers", "Castle.Core developers", "Celery developers",
        "CentOs developers", "Ceylon developers", "Chart.js developers", "ChatGPT API developers",
        "CircleCI developers", "Clarion developers", "ClickHouse developers", "Clipper developers",
        "Clojure developers", "ClojureScript developers", "Cloudera developers", "CMS developers",
        "COBOL developers", "CocoaPods developers", "Cocos2dx developers", "CodeIgniter developers",
        "CoffeeScript developers", "Cognos developers", "Convolutional neural network developers",
        "Cordova developers", "CoreData developers", "Craft CMS developers", "CryEngine developers",
        "Crystal Report developers", "CSV developers", "Cucumber developers", "Cuda developers",
        "Cypress developers", "D developers", "Dagger developers", "Dagster developers", "Dapper developers",
        "Dart developers", "Databricks developers", "DataDog developers", "DDD developers", "Delphi developers",
        "Digital Ocean developers", "Digital strategies developers", "Django developers", "Dlib developers",
        "Docker developers", "Doctrine ORM developers", "Dremio developers", "DRF developers",
        "Drupal developers", "DuckDB developers", "Dynamics AX developers", "DynamoDB developers",
        "EasyNetQ developers", "Eclipse developers", "Eiffel developers", "ElasticSearch developers",
        "Electron developers", "Elixir developers", "Elm developers", "ELT developers", "Ember.js developers",
        "Entity Framework developers", "Erlang developers", "ES5/ES6 developers", "ETL developers",
        "Express.js developers", "ExtJS developers", "F# developers", "FastAPI developers", "Fiddler developers",
        "Firebase developers", "Flask developers", "Formik developers", "Forth developers", "Fortran developers",
        "FreeBSD developers", "Freelance developers", "Gatsby.js developers", "Ghost Inspector developers",
        "Glide developers", "GNU Octave developers", "Godot Engine developers", "Golang developers",
        "Google Cloud developers", "Gorm developers", "Gosu developers", "Gradle developers", "Grafana developers",
        "Graphene developers", "GraphQL developers", "gRPC developers", "Grunt.js developers", "Gulp developers",
        "Hack developers", "Hadoop developers", "Hamcrest developers", "Hangfire developers", "HAProxy developers",
        "HashiCorp Cloud Platform developers", "Haskell developers", "Hasura developers", "Haxe developers",
        "HBase developers", "HCP (Hitachi Content Platform) developers", "Heroku developers", "Hibernate developers",
        "HLSL developers", "Idris developers", "Inertia.js developers", "InfluxDB developers", "Informatica developers",
        "IntelliJ developers", "Ioke developers", "Ionic developers", "Ionic 2 developers", "Jakarta developers",
        "Jasmine developers", "Java developers", "Jenkins developers", "Jest developers", "Joomla developers",
        "jQuery developers", "Jsonnet developers", "Julia developers", "Karate developers", "Katalon Studio developers",
        "Keras developers", "Keycloak developers", "Kibana developers", "KingFisher developers", "Kivy developers",
        "Klarna API developers", "Knockout.js developers", "Koa developers", "Kotlin developers", "Ktor developers",
        "Kubeflow developers", "Kubernetes developers", "LAMP developers", "LangChain developers", "Laravel developers",
        "LaTeX developers", "Lerna developers", "Less CSS developers", "Limbo developers", "Linux developers",
        "Lisp developers", "LIT Element developers", "Livewire developers", "Logstash developers", "Lua developers",
        "Maple developers", "MariaDB developers", "Matlab developers", "Matplotlib developers", "Maven developers",
        "Mercury developers", "Meteor.js developers", "Microservices developers", "Microsoft Silverlight developers",
        "Miva Script developers", "MLOps developers", "MobX developers", "Mocha developers", "Mockito developers",
        "Mojo developers", "Monaca developers", "MongoDB developers", "Moodle developers", "MQTT developers",
        "MSSQL developers", "Mustache developers", "MVVM designers", "MySQL developers", "NativeScript developers",
        "NATS developers", "Neko developers", "Nemerle developers", "Neo4j developers", "Nest.js developers",
        "Newspeak developers", "Next.js developers", "NGINX developers", "NgRx developers", "NGXS developers",
        "Nim developers", "Ninja developers", "NLP developers", "NMock developers", "Node.js developers",
        "NumPy developers", "NUnit developers", "Nuxt.js developers", "OAuth2 developers"
    ],
    Frontend: [
        "Alamofire developers", "Algolia Search developers", "Alpine.js developers", "Angular developers",
        "Angular.js developers", "Backbone developers", "Blazor developers", "Bootstrap developers",
        "Chart.js developers", "Clojure developers", "ClojureScript developers", "CocoaPods developers",
        "Cocos2dx developers", "CodeIgniter developers", "CoffeeScript developers", "CSS developers",
        "Elm developers", "Ember.js developers", "ES5/ES6 developers", "ExtJS developers", "Fiddler developers",
        "Formik developers", "Gatsby.js developers", "Gulp developers", "HTML developers", "Ionic developers",
        "Ionic 2 developers", "jQuery developers", "Jsonnet developers", "Knockout.js developers", "Kotlin developers",
        "Less CSS developers", "LIT Element developers", "Material-UI developers", "Next.js developers",
        "React.js developers", "Redux developers", "Sass developers", "SCSS developers", "TypeScript developers",
        "Vue.js developers"
    ],
    Fullstack: [
        "ASP.NET developers", "Backbone developers", "Blazor developers", "Fullstack developers", "Gatsby.js developers",
        "Meteor.js developers", "Next.js developers", "Nuxt.js developers"
    ],
    Mobile: [
        "Android developers", "Cordova developers", "Flutter developers", "iOS developers", "Ionic developers",
        "Ionic 2 developers", "React Native developers", "Swift developers"
    ],
    GameDevelopment: [
        "Cocos2dx developers", "CryEngine developers", "Godot Engine developers", "Unity developers", "Unreal Engine developers"
    ],
    DataEngineering: [
        "Apache Hive developers", "BigQuery developers", "Databricks developers", "Dremio developers", "DuckDB developers",
        "ETL developers", "Hadoop developers", "Kafka developers", "Pandas developers", "Snowflake developers", "Spark developers"
    ],
    DataScience: [
        "Data analysts", "Data engineers", "Data scientists", "HBase developers", "Julia developers", "Machine Learning developers",
        "Matplotlib developers", "NumPy developers", "Pandas developers", "R developers", "SciPy developers", "TensorFlow developers"
    ],
    MachineLearning: [
        "Data scientists", "Machine Learning developers", "PyTorch developers", "Scikit-learn developers", "TensorFlow developers"
    ],
    BusinessIntelligence: [
        "Business analysts", "Cognos developers", "Microsoft Power BI developers", "Tableau developers"
    ],
    DevOps: [
        "Ansible developers", "CircleCI developers", "Docker developers", "GitHub Actions developers", "Jenkins developers",
        "Kubernetes developers", "Terraform developers", "Travis CI developers"
    ],
    QA: [
        "Appium developers", "Cypress developers", "JUnit developers", "Mocha developers", "Selenium developers", "TestNG developers"
    ],
    Design: [
        "Adobe Creative Suite designers", "Figma designers", "Sketch designers", "UX designers", "UI designers"
    ],
    CloudComputing: [
        "AWS developers", "Azure developers", "Google Cloud developers"
    ]
};

const Skills = () => {
    const [roles, setRoles] = useState([])
    const [letter, setLetter] = useState("")
    const [skills, setSkills] = useState([])

    useEffect(() => {
        let skillsArray = [];
        if (roles.length && letter && letter !== "#") {
            skillsArray = roles.flatMap(role => allSkills[role] || []).filter(skill => skill.charAt(0).toLowerCase() === letter.toLowerCase());
        } else if (letter && letter && letter !== "#") {
            skillsArray = [...new Set([
                ...allSkills.Backend,
                ...allSkills.Frontend,
                ...allSkills.BusinessIntelligence,
                ...allSkills.CloudComputing,
                ...allSkills.DataEngineering,
                ...allSkills.DataScience,
                ...allSkills.DevOps,
                ...allSkills.GameDevelopment,
                ...allSkills.MachineLearning,
                ...allSkills.Mobile])
            ].filter(skill => skill.charAt(0).toLowerCase() === letter.toLowerCase());
        } else if (roles.length) {
            skillsArray = roles.flatMap(role => allSkills[role] || []);
        } else {
            skillsArray = [...new Set([
                ...allSkills.Backend,
                ...allSkills.Frontend,
                ...allSkills.BusinessIntelligence,
                ...allSkills.CloudComputing,
                ...allSkills.DataEngineering,
                ...allSkills.DataScience,
                ...allSkills.DevOps,
                ...allSkills.GameDevelopment,
                ...allSkills.MachineLearning,
                ...allSkills.Mobile])
            ];
        }

        // Add unique and stable keys to each skill
        skillsArray = skillsArray.map((skill, index) => ({ key: `${skill}-${index}`, skill }));
        const sortedSkills = skillsArray.sort((a, b) => a.skill.localeCompare(b.skill));
        setSkills(sortedSkills);
    }, [roles, letter]);




    return (
        <>
            <Helmet>
                <title>Hire Top Tech Experts And Vetted Resources - Teckable</title>
                <meta name="description" content="Discover vetted resources with 500+ in-demand skills. Hire top tech experts with our comprehensive hiring guide to find the perfect talent for your needs." />
                <meta name="keywords" content="tech expert,top tech experts" />
                <link rel="canonical" href="https://www.teckable.com/skills-explore" />
            </Helmet>
            <Box className="skills">
                <Box>
                    <ResponsiveAppBar />
                </Box>
                <section className='find-developers'>
                    <Container maxWidth="lg" sx={{ py: { md: 10, xs: 7 }, pb: { xs: 2 }, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h1>Discover Vetted Resources with 500+ In-Demand Skills</h1>
                        <p>Find the perfect talent for your needs using our comprehensive hiring guide.</p>
                    </Container>
                </section>
                <Search roles={roles} setRoles={setRoles} skills={skills} letter={letter} setLetter={setLetter} allSkills={allSkills} />
                <SkillsList roles={roles} letter={letter} setRoles={setRoles} skills={skills} allSkills={allSkills} />
                <ContactUs />
                <Footer />
            </Box>


        </>
    )
}

export default Skills