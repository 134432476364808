import { Box, Container, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { DataProvider } from './index';

const SelectComponent = ({ label, value, onChange, options }) => {
  return (
    <Box>
      <InputLabel sx={{ textAlign: {xs:"center",md:"start"}, color: "#fff" }}>{label}</InputLabel>
      <Select
        value={value}
        sx={{ background: '#fff' }}
        onChange={onChange}
        // MenuProps={{
        //   PaperProps: {
        //     style: {
        //       maxHeight: 200, 
        //     },
        //   },
        // }}
      >
        {options.map(option => (
          <MenuItem key={option.key || option} value={option.key || option}>
            {option.value || option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export const Selector = () => {
  const { data, setData } = useContext(DataProvider);

  const resourceList = [
    { key: 'software-developers', value: 'Software Developers' },
    { key: 'ui-ux-designers', value: 'UI / UX Designers' },
    { key: 'qa-engineers', value: 'QA Engineers' },
    { key: 'data-scientists', value: 'Data Scientists' },
    {key: 'data-engineers', value:'Data Engineers'},
    { key: 'devops-engineers', value: "DevOps Engineers" },
    { key: 'business-analysts', value: 'Business Analysts' },
    { key: 'project-manager', value: 'Project Manager' }
  ];


  const expectationsList = [
    { key: 40, value: "40h a week (full-time)" },
    { key: 20, value: "20h a week (part-time)" },
  ];

  // const countries = [
  //   { key: 'austria', value: 'Austria' },
  //   { key: 'belgium', value: 'Belgium' },
  //   { key: 'canada', value: 'Canada' },
  //   { key: 'denmark', value: 'Denmark' },
  //   { key: 'finland', value: 'Finland' },
  //   { key: 'france', value: 'France' },
  //   { key: 'germany', value: 'Germany' },
  //   { key: 'luxembourg', value: 'Luxembourg' },
  //   { key: 'netherlands', value: 'The Netherlands' },
  //   { key: 'norway', value: 'Norway' },
  //   { key: 'sweden', value: 'Sweden' },
  //   { key: 'switzerland', value: 'Switzerland' },
  //   { key: 'uk', value: 'United Kingdom' },
  //   { key: 'usa', value: 'United States' },
  //   { key: 'other', value: 'Other' }
  // ];

  const currencies = [
    { key: 'canadian-dollar', value: 'Canadian Dollar (C$)' },
    { key: 'danish-krone', value: 'Danish Krone (kr.)' },
    { key: 'euro', value: 'Euro (€)' },
    { key: 'norwegian-krone', value: 'Norwegian Krone (kr)' },
    { key: 'pound-sterling', value: 'Pound Sterling (£)' },
    { key: 'swedish-krona', value: 'Swedish Krona (kr)' },
    { key: 'swiss-franc', value: 'Swiss Franc (CHF)' },
    { key: 'united-states-dollar', value: 'United States Dollar ($)' }
  ];

  return (
    <section className='selector'>
      <Container maxWidth={"lg"} sx={{mt:{xs:1,md:3}}} >
        <p>What resource are you looking for?</p>
        <Stack direction={{ xs: 'column', md: 'row' }} gap={2} sx={{ justifyContent: "center", mt: { xs: 1, md: 2 } }}>
          <SelectComponent
            label="Resource"
            value={data.resource}
            onChange={(e) => setData((prevData) => ({ ...prevData, resource: e.target.value }))}
            options={resourceList}
          />
          <SelectComponent
            label="Expectations"
            value={data.expectations}
            onChange={(e) => setData((prevData) => ({ ...prevData, expectations: e.target.value }))}
            options={expectationsList}
          />
          {/* <SelectComponent
            label="My Country"
            value={data.country}
            onChange={(e) => setData((prevData) => ({ ...prevData, country: e.target.value }))}
            options={countries}
          /> */}
          <SelectComponent
            label="My Currency"
            value={data.currency}
            onChange={(e) => setData((prevData) => ({ ...prevData, currency: e.target.value }))}
            options={currencies}
          />
        </Stack>
      </Container>
    </section>
  );
};
