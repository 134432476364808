import React from 'react'
import ResponsiveAppBar from '../../components/header'
import { Box } from '@mui/material'
import { FaqHelp } from './faq-help'
import { Footer } from '../../components/footer'
import './_faq.scss'
import { Helmet } from 'react-helmet-async';
const FAQs = () => {
    return (
        <>
            <Helmet>
                <title>Frequently Asked Questions - Teckable</title>
                <meta name="description" content="Find answers about Teckable’s services, pricing, and support to help you get started and make the most of our solutions." />
                <link rel="canonical" href="https://www.teckable.com/faq" />

            </Helmet>
            <Box sx={{ backgroundColor: "#5258fb" }}>
                <ResponsiveAppBar />
            </Box>
            <FaqHelp />
            <Footer />
        </>
    )
}

export default FAQs