import React from 'react'
import ResponsiveAppBar from '../../components/header'
import { Footer } from '../../components/footer'
import { Container } from '@mui/material'
import './_privacy.scss'
import { Helmet } from 'react-helmet-async';

const CookiePolicy = () => {
  return (
    <>
      <Helmet>
        <title>Cookie Policy | Teckable</title>
      </Helmet>
      <ResponsiveAppBar />
      <section className='privacy-policy'>
        <Container maxWidth={'lg'}>
          <h3 >Last update: September 2024</h3>
          <h1>Cookie Policy for Teckable</h1>
          <h3>Disclaimer</h3>
          <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They serve various functions, such as remembering your preferences, enhancing security, and helping us understand how visitors interact with our website. This allows us to improve the functionality and performance of our site, ensuring a better user experience.</p>
          <p>At Teckable (<a href='https://teckable.com/'>teckable.com</a>), a trading name for BRIGHT FUTURE CONSULTANCY LTD, we use cookies to perform several important tasks. Some cookies are essential for the basic operations of our website <a href='https://teckable.com/'>teckable.com</a>, allowing you to navigate and use its features securely. These essential cookies ensure that the website operates smoothly and that your interactions with it are as seamless as possible.</p>
          <p>We also use cookies to gather performance data and analytics. These cookies help us collect anonymous information about how visitors use our website, such as which pages are most frequently visited or if users encounter any errors. This data enables us to continuously improve our website, making it more efficient and user-friendly.</p>
          <p>In addition, we use cookies to remember your preferences and provide enhanced functionality. If you select a preferred language or region, cookies will store this information, allowing us to present the website in a way that suits your needs whenever you return.</p>
          <p>Some cookies are used for advertising and targeting purposes. These cookies help us deliver relevant content and advertisements customised to your interests. They also help us measure the effectiveness of our marketing campaigns, ensuring that the content you see is as relevant and useful as possible.</p>
          <p>It’s important to note that, in addition to the cookies set by Teckable (<a href='https://teckable.com/'>teckable.com</a>), third-party cookies may also be used on our website. These third-party cookies are managed by external service providers and are subject to their own privacy policies. They might be used for purposes such as analysing website traffic, delivering advertisements, or integrating with social media platforms.</p>
          <p>You have control over the use of cookies on our website. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. However, please be aware that disabling cookies may affect your ability to access certain features or functions of our website, potentially leading to a less optimal browsing experience.</p>
          <p>Teckable (<a href='https://teckable.com/'>teckable.com</a>) may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically to stay informed about how we use cookies and how any changes might affect you.</p>
          <p>
            This Cookie Policy is designed to help you understand our use of cookies and to give you the information you need to manage your preferences. Thank you for choosing Teckable (<a href='https://teckable.com/'>teckable.com</a>).
          </p>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default CookiePolicy