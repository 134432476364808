import React from 'react'
import ResponsiveAppBar from '../../components/header'
import { Footer } from '../../components/footer'
import { Container } from '@mui/material'
import './_privacy.scss'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | Teckable</title>
            </Helmet>
            <ResponsiveAppBar />
            <section className='privacy-policy'>
                <Container maxWidth={'lg'}>
                    <h3 >Last update: September 2024</h3>
                    <h1>Privacy Policy for Teckable</h1>
                    <h3>Introduction</h3>
                    <p>Welcome to Teckable (<a href='https://teckable.com/'>teckable.com</a>), a trading name for Bright Future Consultancy Ltd. We are dedicated to safeguarding your personal information and ensuring your privacy. This Privacy Policy provides a comprehensive overview of how we collect, use, store, and protect your personal data when you interact with our website, teckable.com. By using our website, you consent to the practices described in this policy.</p>
                    <h3>Company Information</h3>
                    <ul>
                        <li><strong>Company Name:</strong> Bright Future Consultancy Ltd</li>
                        <li><strong>Trading Name:</strong> Teckable</li>
                        <li><strong>Registered Address:</strong> 128 City Road, London, England EC1V 2NX, United Kingdom</li>
                        <li><strong>Company Number:</strong> 12520976</li>
                        <li><strong>VAT Number:</strong> 348031416</li>
                        <li><strong>Email:</strong>
                            <Link href="mailto:hello@teckable.com" data-test="email-link" className="mail">
                                hello@teckable.com
                            </Link>
                        </li>
                    </ul>
                    <h3>Information We Collect</h3>
                    <p><strong>We collect various types of personal data from you, which include:</strong></p>
                    <ul>
                        <li><strong>Contact Information:</strong> This includes your name, email address, phone number, and other contact details you provide when you register, make enquiries, or communicate with us.</li>
                        <li><strong>Technical Data:</strong> We gather information about your device, including your IP address, browser type, operating system, and other technical information about your visit to our site (e.g., pages visited, duration of visit).</li>
                        <li><strong>Usage Data:</strong> Information about how you use our website and services, such as search queries, access times, and interaction with website features.</li>
                        <li><strong>Communication Data:</strong> Details of any communications you have with us, including customer service enquiries, feedback, and responses to surveys or promotions.</li>
                    </ul>
                    <h3>How We Use Your Information</h3>
                    <p><strong>We use your personal data for several purposes:</strong></p>
                    <ul>
                        <li><strong>To Provide and Improve Our Services:</strong> We use your data to fulfill your requests, process transactions, improve our website functionality, and enhance your overall experience.</li>
                        <li><strong>To Communicate with You:</strong> We may send you updates, newsletters, marketing information, and promotional offers based on your consent or contractual relationship with us.</li>
                        <li><strong>To Ensure Compliance and Legal Obligations:</strong> We process your data to comply with legal requirements, respond to legal requests, and enforce our terms and conditions.</li>
                        <li><strong>To Analyse and Improve Our Website:</strong> We use data analytics to understand user behaviour, track website performance, and make improvements to our services.</li>
                    </ul>
                    <h3>Legal Basis for Processing</h3>
                    <p><strong>Our processing of your personal data is based on:</strong></p>
                    <ul>
                        <li><strong>Consent:</strong> Where you have provided explicit consent for us to process your data for specific purposes.</li>
                        <li><strong>Contract:</strong> To fulfil our contractual obligations with you or to take steps at your request before entering into a contract.</li>
                        <li><strong>Legal Obligation:</strong> To comply with our legal obligations, such as tax, regulatory, and compliance requirements.</li>
                        <li><strong>Legitimate Interests:</strong> Where necessary for our legitimate interests or those of a third party, provided these do not override your rights and freedoms.</li>
                    </ul>

                    <h3> Sharing Your Information</h3>
                    <p><strong>We may share your personal data with:</strong></p>
                    <ul>
                        <li><strong>Service Providers:</strong> Third-party vendors and partners who assist us in operating our website, processing transactions, and delivering services. These may include payment processors, email service providers, and hosting companies.</li>
                        <li><strong>Legal Authorities:</strong> In response to legal requests or to comply with applicable laws, regulations, or legal processes.</li>
                        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of our business or assets, your data may be transferred as part of the transaction.</li>
                    </ul>
                    <h3>Data Security</h3>
                    <p>We implement robust technical and organisational measures to protect your personal data against unauthorised access, disclosure, alteration, or destruction. This includes encryption, secure servers, and regular security assessments. Despite these measures, please be aware that no method of transmission over the internet or electronic storage is completely secure.</p>
                    <h3>Your Rights</h3>
                    <p><strong>Under the UK General Data Protection Regulation (GDPR) and Data Protection Act 2018, you have the following rights regarding your personal data:</strong></p>
                    <ul>
                        <li><strong>Right of Access:</strong> You can request a copy of the personal data we hold about you.</li>
                        <li><strong>Right to Rectification:</strong> You can request corrections to any inaccurate or incomplete data.</li>
                        <li><strong>Right to Erasure:</strong> You can request deletion of your data in certain circumstances, such as when it is no longer necessary for the purposes for which it was collected.</li>
                        <li><strong>Right to Restrict Processing:</strong> You can request the restriction of processing under specific conditions, such as when you contest the accuracy of the data.</li>
                        <li><strong>Right to Object:</strong> You can object to processing based on legitimate interests or for direct marketing purposes.</li>
                        <li><strong>Right to Data Portability:</strong> You can request to transfer your personal data to another organization, where applicable.</li>
                    </ul>
                    <p>To exercise any of these rights, please contact us using the details provided below.</p>
                    <h3>Cookies and Tracking Technologies</h3>
                    <p> <strong>Our website uses cookies and similar tracking technologies to enhance your experience. Cookies are small files placed on your device that collect information about your use of our site. We use cookies for various purposes, including:</strong> </p>
                    <ul>
                        <li><strong>Functional Cookies:</strong> To remember your preferences and settings.</li>
                        <li><strong>Analytical Cookies:</strong> To track and analyze website usage and performance.</li>
                        <li><strong>Marketing Cookies:</strong> To deliver relevant advertisements and measure the effectiveness of our marketing campaigns.</li>
                    </ul>
                    <p>You can manage your cookie preferences through your browser settings. Please note that disabling certain cookies may affect the functionality of our website.</p>
                    <h3>Links to Other Websites</h3>
                    <p>Our website may contain links to other websites that are not operated by us. We are not responsible for the privacy practices or content of these external sites. We recommend reviewing their privacy policies before providing any personal information.</p>
                    <h3>Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with an updated effective date. We encourage you to periodically review this policy to stay informed about how we are protecting your information.</p>
                    <h3>Contact Us</h3>
                    <p><strong>If you have any questions or concerns about this Privacy Policy or how we handle your personal data, please contact us at:</strong></p>
                    <ul>
                        <li><strong>Email:</strong>
                            <Link href="mailto:hello@teckable.com" data-test="email-link" className="mail">
                                hello@teckable.com
                            </Link>
                        </li>
                        <li><strong>Registered Address:</strong> 128 City Road, London, England EC1V 2NX, United Kingdom</li>
                    </ul>
                    <p>Thank you for visiting Teckable (<a href='https://teckable.com/'>teckable.com</a>)</p>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default PrivacyPolicy