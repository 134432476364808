import React from 'react'
import '../index.scss'


export const CustomButton = ({ onClick, text,className,type="btn", disabled=false }) => {
    return (
        <button className={`btn ${className}`} type={type} disabled={disabled} onClick={onClick ? onClick : undefined}>
            {text}
        </button>
    )
}
