import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const GTMHandler = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtmInitialized) {
      const tagManagerArgs = {
        gtmId: 'GTM-TD5LSQNX', 
      };
      TagManager.initialize(tagManagerArgs);
      window.gtmInitialized = true; 
    }
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: location.pathname + location.search,
      },
    });
  }, [location]);

  return null;  // This component doesn't render anything
};

export default GTMHandler;
