import React from 'react'
import ResponsiveAppBar from '../../components/header'
import { Box } from '@mui/material'
import './_index.scss'

const Developers = () => {
    return (
        <>
            <ResponsiveAppBar />
            <Box className="developers">
            </Box>

        </>
    )
}

export default Developers