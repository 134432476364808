import { Autocomplete, Container, Divider, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {  Element, scroller } from 'react-scroll'

const combinedQuestions = {
    generalQuestions: [
    //     {
    //         question: "How long does it take to get started?",
    //         answer: "On average, we can match the right resources to your company within 5 days of understanding your requirements, not months. We will present as many relevant, pre-vetted candidates as needed, and the final choice is always yours."
    //     },
    //     {
    //         question: "How can Teckable be sure that it's providing the resources my company needs?",
    //         answer: "Our recruitment and vetting process is exceptionally thorough. We begin by fully understanding our clients' needs, then we set out to find and present the right resources. Only 5% of the 1000+ applicants we receive each month make it through our rigorous vetting process, which includes extensive testing of hard and soft skills, assessments of English proficiency and communication skills, and thorough technical interviews. This comprehensive approach ensures that we can guarantee you'll be presented with highly qualified, trusted resources who will be the perfect match for your company."
    //     },
    //     {
    //         question: "Do the resources speak English?",
    //         answer: "Yes, all our resources speak English fluently and possess excellent communication skills. These are key requirements for us when attracting top talent, and they form an integral part of our vetting process. Additionally, our talent acquisition team conducts thorough interviews to ensure that candidates are a good cultural fit for Western tech teams."
    //     },
    //     {
    //         question: "What type of resource expertise does Teckable offer?",
    //         answer: "Our network of senior resources encompasses over 100 highly sought-after skills. We have backend developers proficient in PHP, .NET, C#, Python, Ruby, Golang, and Java, as well as frontend developers skilled in React.js, Vue.js, Angular, and more. Additionally, we work with designers, QA specialists, DevOps Engineers, Data Scientists, Data Engineers (Apache Spark, Scala, Python), Machine Learning Engineers, Business Intelligence experts (Qlik Sense, QlikView, MS Power BI), Product Owners, Program Managers, Project Managers, and Scrum Masters."
    //     },
    //     {
    //         question: "Does Teckable take care of leading the project?",
    //         answer: "Teckable is always available to guide both developers and clients throughout a project, ensuring an effective working relationship. We can assign a senior partner resource to work closely with clients, providing technical assistance or project leadership as needed. All resources recruited by Teckable are highly self-motivated and communicative, making them easy to integrate into your team."
    //     },
    //     {
    //         question: "How many hours per week can I hire Teckable developers?",
    //         answer: "Teckable offers top developers who are available and eager to work on full-time engagements. However, we understand that this may not always suit your needs, so we offer flexibility with a minimum commitment of 40 hours per month (equivalent to 2 weeks per month)."
    //     },
        {
            question: "How quickly can we begin?",
            answer: "We typically connect you with suitable talent within just 5 days of understanding your needs—no extended wait times. We provide as many pre-screened candidates as necessary, and you make the final hiring decision."
        },
        {
            question: "Do the resources have strong English skills?",
            answer: "Absolutely. All of our team members are fluent in English and also have outstanding communication abilities. Mastery of English is a fundamental criterion for joining our pool of talent, and we thoroughly assess this during our recruitment process. We conduct comprehensive interviews to ensure every candidate is well-suited to work seamlessly within global tech environments."
        },
        {
            question: "Does Teckable manage the project?",
            answer: "Teckable facilitates seamless interactions between your team and our experts, but we don’t take over project management by default. If you need more hands-on oversight, we can assign a senior consultant to provide project leadership or technical expertise. Our professionals are self-motivated and adaptable, ensuring a smooth integration into your current team while you retain overall control."
        },
        {
            question: "How does Teckable ensure it provides the right resources for my business?",
            answer: "Teckable employs a rigorous selection process to match your needs with the perfect candidates. We start by gaining a thorough understanding of your requirements. From there, we sift through thousands of applicants each month, with only a select few making it through our detailed screening. This process includes comprehensive assessments of technical abilities, English communication skills, and interpersonal qualities to ensure you receive only the most qualified and compatible professionals for your team."
        },
        {
            question: "What resource expertise can I access through Teckable?",
            answer: "Teckable connects you with a diverse pool of highly skilled professionals across various fields. We have backend developers proficient in PHP, .NET, C#, Python, Ruby, Golang, and Java, as well as frontend developers skilled in React.js, Vue.js, Angular, and more. Additionally, we work with designers, QA specialists, DevOps Engineers, Data Scientists, Data Engineers (Apache Spark, Scala, Python), Machine Learning Engineers, Business Intelligence experts (Qlik Sense, QlikView, MS Power BI), Product Owners, Program Managers, Project Managers, and Scrum Masters."
        },
        {
            question: "What is the minimum number of hours in a week I can hire Teckable developers for?",
            answer: "Teckable’s developers are primarily available for full-time projects, but we offer flexible arrangements to accommodate different needs. You can engage our developers for a minimum of 40 hours per month, which translates to about 2 weeks of work each month, giving you the flexibility to scale your resource usage as required."
        }
    ],
    recruitmentQuestions: [
        // {
        //     question: "Is there a notice period?",
        //     answer: "Yes, we aim to include a notice period in our agreements to provide added security for both our clients and resources. The specifics can be negotiated on a case-by-case basis. We also start with a risk-free trial period where you only pay if you’re happy, ensuring the developer is the right fit from the start."
        // },
        // {
        //     question: "Where do your resources work?",
        //     answer: "Our resources typically work from either a co-working space or their home office."
        // },
        {
            question: "Do you have a standard notice period for agreements?",
            answer: "A notice period is part of our agreements to safeguard both our customers and staff. Terms can be customized to suit your situation, and you can begin with a trial period to ensure the developer meets your expectations before committing."
        },
        {
            question: "Where are your employees typically based?",
            answer: "Our staff generally perform their tasks from either communal workspaces or their home setups."
        }
    ],
    pricingQuestions: [
        // {
        //     question: "How much does it cost to hire Teckable resources?",
        //     answer: "Prices range from £2,500 to £5,000 per month for full-time resources (40 hours per week). There are no hidden fees or starting costs. Exact pricing within this range varies based on the required skills, the seniority of the resources, and the number of hours per month (minimum 40 hours per month). With Teckable, you'll always know exactly how much you're paying and what you're paying for."
        // },
        // {
        //     question: "How does payment work?",
        //     answer: "Teckable issues invoices monthly, with a 20-day payment term."
        // },
        // {
        //     question: "Are there any upfront recruitment or contract-related costs?",
        //     answer: "No, there are no upfront fees. Unlike recruitment companies and talent marketplaces, we don't charge for any costs associated with contracts, recruitment, or anything else."
        // },
        // {
        //     question: "What if I'm not satisfied with my Teckable resource after one week?",
        //     answer: "If you're not satisfied after the first week, that week is free of charge. We will then work diligently to find a new Teckable resource that better suits your needs."
        // },
        {
            question: "What is the cost of hiring Teckable resources?",
            answer: "The cost for full-time resources (40 hours per week) ranges between £400 and £800 per month. The exact cost depends on the specific skills required, the level of expertise, and the total hours booked (minimum of 40 hours per month). Pricing is transparent, with no hidden fees or upfront charges."
        },
        {
            question:'Do you require any payments before recruitment or contracts are finalized?',
            answer: "No advance payments are needed. You won’t face recruitment or contract-related costs like with other services."
        },
        {
            question: "What is the payment process?",
            answer: "We send out monthly invoices, giving you 20 days to complete the payment."
        },
        {
            question: 'What if my Teckable resource isn’t the right fit after the first week?',
            answer: "If the match isn’t perfect in the first week, you won’t be charged for that time. We’ll then promptly search for an appropriate professional to meet your specifications."
        }

    ],
    intellectualPropertyQuestions: [
        // {
        //     question: "Who owns the legal rights to the work created by Teckable resources?",
        //     answer: "All billable work performed by Teckable resources belongs to you, the client. This includes, but is not limited to, source code, documentation, illustrations, images, databases, and written content."
        // },
        // {
        //     question: "Do you have liability insurance?",
        //     answer: "Yes, Teckable carries liability insurance."
        // },
        // {
        //     question: "How is confidentiality managed?",
        //     answer: "At Teckable, both our resources and internal employees ensure that all client information is kept strictly confidential. This applies to information disclosed in writing, verbally, through code, or any other format. We sign a Master Agreement that includes confidentiality clauses, and we also require all onboarded resources to sign a confidentiality agreement."
        // },

        {
            question: "Who holds the rights to the work produced by Teckable Resources?",
            answer:'All work completed by Teckable professionals, including source code, documentation, designs, images, databases, and written content, is fully owned by you, the client.'
        },
        {
            question: "Does Teckable have liability insurance?",
            answer: "Yes, we have liability insurance to ensure comprehensive protection for our clients and resources."
        },
        {
            question: "How is client confidentiality handled?",
            answer: "At Teckable, protecting client information is our top priority. We ensure that all shared data, whether communicated in writing, verbally, through code, or any other means, remains secure. We sign a core agreement with confidentiality terms and require all resources to sign confidentiality agreements as well."
        }

    ]
};

const questions = [
    "How quickly can we begin?",
    "Do the resources have strong English skills?",
    "Does Teckable manage the project?",
    "How does Teckable ensure it provides the right resources for my business?",
    "What resource expertise can I access through Teckable?",
    "What is the minimum number of hours in a week I can hire Teckable developers for?",
    "Do you have a standard notice period for agreements?",
    "Where are your employees typically based?",
    "What is the cost of hiring Teckable resources?",
    "Do you require any payments before recruitment or contracts are finalized?",
    "What is the payment process?",
    "What if my Teckable resource isn’t the right fit after the first week?",
    "Who holds the rights to the work produced by Teckable Resources?",
    "Does Teckable have liability insurance?",
    "How is client confidentiality handled?"
];


// const questions = [
//     "How long does it take to get started?",
//     "What type of technical expertise does Teckable offer?",
//     "How can Teckable be sure that it's the developer my company needs?",
//     "Does Teckable take care of leading the project?",
//     "Do the developers speak English?",
//     "How many hours per week can I hire Teckable developers?",
//     "Is there a notice period?",
//     "Can the developer work less than full-time?",
//     "Where are your developers located?",
//     "Can I have the developer at my office?",
//     "Where do your developers work?",
//     "How much does it cost to hire Teckable developers?",
//     "What if I'm not satisfied with my Teckable developer after one week?",
//     "How does payment work?",
//     "Are there any upfront recruitment or contract-related costs?",
//     "How many hours per week can I hire Teckable developers?",
//     "Who will own the legal rights to the work created by a developer from Teckable?",
//     "How is confidentiality managed?",
//     "Do you have liability insurance?"
// ];

const popularQuestions = [
    "How does Teckable ensure it provides the right resources for my business?",
    "Do the resources have strong English skills?",
    "Do you have a standard notice period for agreements?",
    "What is the cost of hiring Teckable resources?",
    "What if my Teckable resource isn’t the right fit after the first week?",
    "How quickly can we begin?",
    "How is client confidentiality handled?",
    "Who holds the rights to the work produced by Teckable Resources?",
];

export const FaqHelp = () => {
    const [data, setData] = useState([]);
    const [scrollTo, setScrollTo] = useState('');

    useEffect(() => {
        setTimeout(() => {
            setScrollTo("");
        }, 1000);
    }, [scrollTo]);

    useEffect(() => {
        const savedPage = localStorage.getItem("page");
        setScrollTo(savedPage || '');
        setTimeout(() => {
            localStorage.removeItem('page');
        }, 1000);
    }, []);

    const handleQuestionChange = (_, newValue) => {
        setScrollTo(newValue);
        setData(prevData => {
            return prevData.includes(newValue)
                ? prevData
                : [...prevData, newValue];
        });
    };

    const handleClick = (question) => {
        setData(prevData => {
            return prevData.includes(question)
                ? prevData.filter(item => item !== question)
                : [...prevData, question];
        });
    };

    return (
        <>
            <section className='faq-help'>
                <Container maxWidth="lg">
                    <h1>Here’s how we can assist you</h1>
                    <p>Find detailed information about our services and pricing in our FAQs. If you require additional assistance, please email us at <a href="mailto:hello@teckable.com">hello@teckable.com</a>.</p>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={questions}
                        openOnFocus={false}
                        onChange={handleQuestionChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Quick search"
                                sx={{
                                    color: "#fff",
                                    width: { xs: "100%", md: "50%" },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#fff',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#fff',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#fff',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#fff',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#fff',
                                    },
                                    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                                        color: '#fff',
                                    },
                                    '& .MuiAutocomplete-endAdornment': {
                                        display: 'none',
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#fff',
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />

                    <h3>Popular questions</h3>
                    <Grid container spacing={2}>
                        {popularQuestions.map(question => (
                            <Grid xs={12} key={question} item md={3}>
                                <div className='popular-questions' onClick={()=>handleQuestionChange(null,question)}>{question}</div>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </section>

            {Object.keys(combinedQuestions).map(property => (
                <section className='questions' key={property}>
                    <Container maxWidth="lg">
                        <h2>{property === "generalQuestions" ? "Some Common Questions Answered" : property === "recruitmentQuestions" ? "Questions related to recruitment" : property === "pricingQuestions" ? "Questions related to Pricing" : "Intellectual Property Protection and Data Security"}</h2>
                        <Grid container spacing={{ xs: 2, md: 5 }}>
                            {combinedQuestions[property].map(item => (
                                <Grid key={item.question} item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <Element name={item.question}>
                                        <h5
                                            style={{ color: data.includes(item.question) ? "#5258fb" : "#000" }}
                                            onClick={() => handleClick(item.question)}
                                        >
                                            {item.question} {data.includes(item.question) ? <RemoveIcon /> : <AddIcon />}
                                        </h5>
                                        <p style={{ display: data.includes(item.question) ? "block" : "none" }}>
                                            {item.answer}
                                        </p>
                                    </Element>
                                    <div style={{ minHeight: "40px" }}>
                                        <Divider sx={{ border: "1px solid #000" }} />
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </section>
            ))}

            <ScrollComponent scrollTo={scrollTo} />
        </>
    );
};

const ScrollComponent = ({ scrollTo }) => {
    useEffect(() => {
        if (scrollTo) {
            scroller.scrollTo(scrollTo, {
                delay: 0,
                offset:-100,
                smooth: true
            });
        }
    }, [scrollTo]);

    return null;
};